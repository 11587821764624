import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import BackgroundImage from "../components/BackgroundImage";
import { getContactBackgroundImage } from "../services/Contact";

function Contact() {
  const [contactBackground, setContactBackground] = useState({});

  useEffect(() => {
    getContactBackgroundImage().then((backgroundInfo) => {
      setContactBackground(backgroundInfo);
    });
  }, []);
  return (
    <>
      <Navbar />
      <BackgroundImage
        src={contactBackground.src}
        title={contactBackground.title}
        subTitle={contactBackground.subTitle}
      />

      <h1>Contact Us</h1>
    </>
  );
}

export default Contact;
