import React, { useEffect, useState } from "react";
import "../styles/Main.css";
import { Button, Drawer, Layout, Menu } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { getNavbarItems } from "../services/Navbar";

const { Header } = Layout;

function Navbar() {
  const [navbarList, setNavbarList] = useState([]);
  const [current, setCurrent] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getNavbarItems().then((response) => {
      setNavbarList(response);
    });
  }, []);

  const goToPage = (e) => {
    setCurrent(e.key);
    window.history.pushState("", "", `${e.key}`);
    window.location = `${e.key}`;
  };

  return (
    <Layout>
      <Header>
        <Button
          className="menu-btn"
          icon={<MenuUnfoldOutlined />}
          onClick={() =>{
            setOpen(true);
          }}
        />
        <Drawer
          className="menu-drawer"
          placement="right"
          theme="dark"
          onClose={() =>{
            setOpen(false);
          }}
          open={open}
        ></Drawer>
        <Menu
          className="navbar-menu"
          items={navbarList}
          mode="horizontal"
          theme="light"
          onClick={goToPage}
          selectedKeys={[current]}
          disabledOverflow="true"
        />
      </Header>
    </Layout>
  );
}

export default Navbar;
