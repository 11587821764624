import React,{useState, useEffect} from "react";
import Navbar from "../components/Navbar";
import BackgroundImage from "../components/BackgroundImage";
import { getServiceBackgroundImage } from "../services/Services";
import OurServices from "../components/OurServices";

function Service() {
  const [serviceBackground, setServiceBackground] = useState({});

  useEffect(() =>{
    getServiceBackgroundImage().then((backgroundInfo) =>{
      setServiceBackground(backgroundInfo);
    });
  },[]);

  return (
    <>
      <Navbar />
      <BackgroundImage
        src={serviceBackground.src}
        title={serviceBackground.title}
        subTitle={serviceBackground.subTitle}
      />
      <OurServices/>
    </>
  );
}

export default Service;
