import React, { useState, useEffect } from "react";
import { Row, Col, Avatar } from "antd";
import { getServiceIntro, getServiceList } from "../services/Services";

function OurServices() {
  const [serviceList, setServiceList] = useState([]);
  const [serviceIntro, setServiceIntro] = useState({});

  useEffect(() => {
    getServiceIntro().then((introInfo) => {
      setServiceIntro(introInfo);
    });
  }, []);
  useEffect(() => {
    getServiceList().then((listInfo) => {
      setServiceList(listInfo);
    });
  }, []);
  return (
    <div className="new-section-alt">
      <div className="container">
        <Row>
          <Col span={8} className="service-font">
            <div>
              <span className="light">{serviceIntro.titleHead}</span>
              <span className="bold"> {serviceIntro.title}</span>
              <br />
              <span className="service-text">{serviceIntro.subTitle}</span>
            </div>
          </Col>
          <Col span={1}></Col>
          <Col span={15}>
            <Row>
              {serviceList.map((service) => (
                <Col span={12} className="standard-font" key={service.title}>
                  <div className="service-innercard">
                    <Row className="standard-font">
                      <Col span={4}>
                        <Avatar
                          className="service-avatar"
                          src={service.avatar}
                        />
                      </Col>
                      <Col span={20} className="standard-font">
                        <span className="bold">{service.title}</span>
                      </Col>
                      <br />
                    </Row>

                    <div className="standard-font">
                      <br />
                      <span className="service-detail">
                        {service.description}
                      </span>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default OurServices;
