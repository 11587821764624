import { createBrowserRouter } from "react-router-dom";
import Home from "../pages/Home";
import Contact from "../pages/Contact";
import Project from "../pages/Projects";
import Service from "../pages/Services";


const router = createBrowserRouter([
    {
        path: '/',
        element: <Home/>
    },
    {
        path: '/contactus',
        element: <Contact/>
    },
    {
        path : '/projects',
        element: <Project/>
    },
    { 
        path: '/services',
        element: <Service/>
    }
]);

export default router;