import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { getAboutContent } from "../services/Home";

function AboutUs() {
  const [aboutInfo, setAboutInfo] = useState([]);

  useEffect(() => {
    getAboutContent().then((about) => {
      setAboutInfo(about);
    });
  }, []);

  return (
    <div className="new-section">
      <div className="container">
        <Row justify={"center"} className="about-text">
          <Col span={5}>
            <div className="about-preview">
              <span className="bold">{aboutInfo.yearCount}</span>
              <span className="light">{aboutInfo.time}</span>
              <span className="thin"> {aboutInfo.subText}</span>
            </div>
          </Col>
          <Col span={7}>
            <div>
              <br />
              <span className="thin">{aboutInfo.welcomeText}</span>
              <span className="light">{aboutInfo.name}</span>
            </div>
          </Col>
          <Col span={12}>
            <div className="about-note">{aboutInfo.aboutNote}</div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AboutUs;
