import React from "react";
import { Carousel, Image, Typography } from "antd";
import "../styles/Main.css";
import { getCarouselItems } from "../services/Carousels";
import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import AboutUs from "../components/AboutUs";
import OurServices from "../components/OurServices";

const { Text } = Typography;

function Home() {
  const [carouselList, setCarouselItems] = useState([]);

  useEffect(() => {
    getCarouselItems().then((response) => {
      setCarouselItems(response);
    });
  }, []);

  return (
    <>
      <Image.PreviewGroup>
        <Navbar />
        <Carousel autoplay>
          {carouselList.map((carousel) => (
            <div className="carousel" key={carousel.key}>
              <Image
                className="carousel-image"
                preview={false}
                src={carousel.img}
              />
              <div className="carousel-text">
                <h1>{carousel.text}</h1>
                <Text keyboard className="image-subtext">
                  {carousel.subText}
                </Text>
              </div>
            </div>
          ))}
        </Carousel>
      </Image.PreviewGroup>
      <AboutUs/>
      <OurServices/>
    </>
  );
}

export default Home;
