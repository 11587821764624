export const getServiceBackgroundImage = async () => {
  return {
    src: "https://images.wallpaperscraft.com/image/single/skyscraper_building_architecture_112550_3840x2160.jpg",
    title: "Our Services",
    subTitle: "Catering to Your Needs with Our Exceptional Services",
  };
};

export const getServiceIntro = async () =>{
  return{
    title:"Services",
    titleHead:"top class",
    subTitle:"Expert engineering services provided at budget-friendly prices by our team of experienced professionals."
  };
};

export const getServiceList = async () => {
  return [
    {
      title: "Construction Service",
      avatar: "https://cdn-icons-png.flaticon.com/512/2823/2823550.png",
      description:
        "A complete range of engineering solutions, including construction, renovation, and interior design services, delivered by a team of experienced professionals.",
    },
    {
      title: "Municipal Drawing",
      avatar: "https://cdn-icons-png.flaticon.com/512/1623/1623953.png",
      description:
        "Professional and expert municipal drawing services for both residential and commercial buildings with high-quality work, ensuring that all government regulations and requirements are met.",
    },

    {
      title: "Energy Efficient Building Design",
      avatar: "https://cdn-icons-png.flaticon.com/512/2942/2942499.png",
      description:
        "Specialist design solutions for energy-efficient buildings from experienced professionals specializing in the latest energy-efficient technologies and principle to create customized designs reducing energy consumption and costs.",
    },

    {
      title: "Interior Design",
      avatar: "https://cdn-icons-png.flaticon.com/512/2984/2984792.png",
      description:
        "Personalized interior design services tailored to meet the unique needs and preferences of our clients, ensuring that each project is completed to the highest quality standards, whether it's a single room or an entire property.",
    },
    {
      title: "Architectural Design",
      avatar: "https://cdn-icons-png.flaticon.com/512/681/681560.png",
      description:
        "Architectural design with a focus on delivering high-quality work that prioritizes functionality, aesthetics, and sustainability, while providing personalized attention to detail and a seamless design process from concept to completion.",
    },
    {
      title: "Urban Planning",
      avatar: "https://cdn-icons-png.flaticon.com/512/8151/8151255.png",
      description:
        "Urban planning services tailored to create sustainable, livable, and efficient urban environments, delivering high-quality work adhering to timelines and budgets, and personalized attention to detail throughout the planning process.",
    },
  ];
};
