export const getNavbarItems = async () => {
  return [
    {
      key: "/",
      label: "Home",
    },
    {
      key: "/projects",
      label: "Projects",
    },
    {
      key: "/contactus",
      label: "Contact Us",
    },
    {
      key: "/services",
      label: "Services",
    },
  ];
};
