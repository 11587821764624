import React from "react";
import { Image, Typography } from "antd";
const { Text } = Typography;

function BackgroundImage({src,title,subTitle}) {
  return (
    <div className="background-image">
      <Image
        className="main-image"
        preview={false}
        src= {src}
      />
      <div className="image-text">
        <h1>{title}</h1>
        <Text keyboard className="image-subtext">{subTitle}</Text>
      </div>
    </div>
  );
}

export default BackgroundImage;