export const getAboutContent = async() => {
    return(
        {
            name: "Kalpana Engineering Solutions",
            aboutNote: "Kalpana Engineering Solutions is a consultancy that provides comprehensive engineering solutions for residential and commercial buildings. Our services include design, construction, interior design, and consultation for various engineering needs. With five years of experience, we are committed to providing personalized solutions and building long-term relationships with our clients. Our team of experts delivers projects on time, within budget, and to the highest quality standards. We are dedicated to exceptional customer service and helping clients bring their vision to life.",
            yearCount:"5+",
            time:"years",
            subText:"proven excellence",
            welcomeText:"welcome to",
        }
    )
}