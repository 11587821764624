import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import BackgroundImage from "../components/BackgroundImage";
import { getProjectBackgroundImage } from "../services/Projects";

function Project() {
  const [projectBackground, setProjectBackground] = useState({});

  useEffect(() => {
    getProjectBackgroundImage().then((backgroundInfo) => {
      setProjectBackground(backgroundInfo);
    });
  }, []);
  return (
    <>
      <Navbar />
      <BackgroundImage
        src={projectBackground.src}
        title={projectBackground.title}
        subTitle={projectBackground.subTitle}
      />
      <h1>Projects</h1>
    </>
  );
}

export default Project;
