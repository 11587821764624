export const getCarouselItems = async () => {
  return [
    {
      key:1,
      subText: "Providing all kinds of Construction Services ",
      text: "Constructing Your Living Dreams",
      img:"https://images.wallpaperscraft.com/image/single/skyscraper_building_architecture_199881_3840x2160.jpg",
    },
    {
      key:2,
      subText: "We design all kinds of Buildings",
      text: "We Build Buildings Professionally",
      img:"https://images.wallpaperscraft.com/image/single/skyscraper_building_architecture_112550_3840x2160.jpg",
    },
    {
      key:3,
      subText: "We take care of your Interior Designs",
      text: "Make every corner of your space Stylish",
      img:"https://images.wallpaperscraft.com/image/single/building_skyscraper_architecture_211860_3840x2160.jpg",
    },
  ];
};


